import WebServiceRequest from "../Api/WebServiceRequest";

class GetAllFormInputData extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("FormInputData/GetAll");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

export { GetAllFormInputData };
