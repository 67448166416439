<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="mb-3 w-100 d-flex align-items-center justify-content-between">
        <h1>صندوق ورودی فرم های تماس</h1>
      </div>
      <b-row>
        <b-col cols="12">
          <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="dataList"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
          >
            <template #head()="data">
              <p class="custom-whitespace-nowrap">{{ data.label }}</p>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
export default {
  title: "مشاهده‌ی یک پیام",
  mounted() {
    this.getThisForm();
  },
  data() {
    return {
      dataList: [],
      isLoading: false,
      count: 10,
      pageNumber: 1,
      totalCount: null,
      searchCommand: null,
      myTableColumns: [],
    };
  },
  methods: {
    async getThisForm() {
      try {
        this.isLoading = true;
        let qParams = {
          formId: this.$route.params.formId,
          count: 0,
          pageNumber: 0,
        };
        let _this = this;
        let getAllFormInputData = new GetAllFormInputData(_this);
        getAllFormInputData.setRequestParam(qParams);
        await getAllFormInputData.fetch((response) => {
          if (response.isSuccess) {
            _this.dataList = response.data.forms[0].users;
            if (_this.dataList[0]) {
              _this.dataList[0].forEach((item, index) => {
                _this.myTableColumns.push({
                  key: `${index}.value`,
                  label: item.selectedInputLabel,
                });
              });
            }
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BCard,
    BPagination,
    BContainer,
    BFormRow,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BFormTextarea,
    BOverlay,
  },
};
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import { GetAllFormInputData } from "@/libs/Api/FormInputData";
</script>
<style>
.custom-whitespace-nowrap {
  white-space: nowrap !important;
}
</style>
